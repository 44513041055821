


















































import {Component, Prop, Vue} from "vue-property-decorator";
import {Post} from "@/services/Post";
import {PostService} from "@/services/PostService";
import {BButton} from 'buefy/dist/esm/button'
import {VoteType} from "@/services/Post";

@Component({components: {'b-button': BButton}})
export default class Req extends Vue {
  @Prop() private item!: Post;

  canVote = PostService.Instance.canVote(this.item.id)
  highlighted = false

  get formattedDate() {
    return new Date(this.item.publishDate).toLocaleDateString("ru-Ru")
  }

  async voteStandard() {
    await this.vote(VoteType.Standard)
  }

  async voteChurch() {
    await this.vote(VoteType.LargeGroup)
  }

  async share() {
    await navigator.share({
      text: this.item.text,
      title: "Нужда на жертвеннике",
      url: window.location.origin + "/#" + this.item.id
    })
  }
  
  mounted(){
    const id = Number(this.$route.params.id)
    if (id == this.item.id) {
      this.highlighted = true
      this.$el.scrollIntoView({behavior: "smooth"})
    }
  }

  async vote(type) {
    this.canVote = false
    await PostService.Instance.vote(this.item, type)
  }
}
