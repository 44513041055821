






import {Component, Vue} from "vue-property-decorator";
import CardList from "@/components/CardList.vue";
import Footer from "@/components/Footer.vue";
@Component({
  components: {
    Footer,
    CardList
  }
})
export default class App extends Vue {
}
