

























import {Component, Prop, Vue} from "vue-property-decorator";
import {PostService} from "@/services/PostService";
import SuggestForm from "@/components/SuggestForm.vue";
import {BButton} from 'buefy/dist/esm/button'
import {BModal} from 'buefy/dist/esm/modal'
import {ConfigService} from "@/services/ConfigService";
import firebase from "firebase/app";

@Component({
  components: {SuggestForm, 'b-button': BButton, 'b-modal': BModal}
})
export default class HelloWorld extends Vue {
  formOpened = false
  canSuggest = PostService.Instance.canSuggest()
  verse = ""

  get needSubscribe() {
    return !PostService.Instance.isSubscribed() && firebase.messaging.isSupported()
  }

  async subscribe() {
    await PostService.Instance.subscribe()
  }

  setVerse(verse) {
    this.verse = verse
  }

  created() {
    new ConfigService().getVerse().then(this.setVerse)
  }

  openForm() {
    this.formOpened = true
  }

  closeForm() {
    this.canSuggest = false
    this.formOpened = false
  }
}
