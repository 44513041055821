import {config} from "@/services/apiConfig";

export class AuthService {

    private static baseUrl = `${config.baseUrl}/auth`

    static getSavedToken() {
        return window.localStorage.getItem('token')
    }
    static saveToken(token){
        window.localStorage.setItem('token', token)
    }

    static async isTokenValid(): Promise<boolean> {
        const token = this.getSavedToken()
        if (!token) {
            return false
        }
        const resp = await fetch(this.baseUrl, {headers: {"Authorization": `Bearer ${token}`}})
        return resp.status == 200
    }
    

    static async getToken(login, password): Promise<string | null> {
        const resp = await fetch(this.baseUrl, {
            method: "POST",
            body: JSON.stringify({login, password}),
            headers: {'content-type': "application/json"}
        })
        if (resp.status == 200) {
            return await resp.text()
        }
        return null;
    }
}