















import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import {Post} from "@/services/Post";
import {PostService} from "@/services/PostService";
import Req from "@/components/Req.vue";

@Component({
  components: {Req}
})
export default class HelloWorld extends Vue {
  items: Post[] = []

  async created() {
    await PostService.Instance.load()
    this.items = PostService.Instance.items
  }
}
