import {config} from "@/services/apiConfig";
import {RequestHelper} from "@/services/RequestHelper";

export class ConfigService {

    private baseUrl = `${config.baseUrl}/config`

    private load(): Promise<any> {
        return RequestHelper.get(this.baseUrl)
    }

    async getVerse(): Promise<string> {
        const c = await this.load()
        return c.verse
    }
}